<nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
  <a class="navbar-brand" [routerLink]="'.'">ocanuz</a>

  <!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
  <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>

  <!-- Step 2: Add the ngbCollapse directive to the element below. -->
  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
    <ul class="navbar-nav">
      <!-- <li class="nav-item active">
        <a class="nav-link" [routerLink]="'.'" (click)="isMenuCollapsed = true">Features</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'.'" (click)="isMenuCollapsed = true">Examples</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'.'" (click)="isMenuCollapsed = true">About</a>
      </li> -->
    </ul>
  </div>
</nav>
<router-outlet></router-outlet>